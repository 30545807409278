module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-theme-ui@0.16.2_@emotion+react@11.13.3_@types+react@18.3.10_react@18.3.1__@mdx-_kg5tft6sd2cpmbcyztrwvvalim/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.11.1_jiti@1.21.0__6hlsyf4jjncufozv7grqnosmme/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-GX6YBKFNSN"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.13.2_gatsby-plugin-sharp@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_esli_qmsbmdihjvsak57k4vjnncpe4q/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"loading":"lazy","linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-autolink-headers@6.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.11.1_jiti@1._7yslmd5jbn6f6nk64gqj35ivze/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.11.1_jiti@1.21.0___e_7apj7xe4b7rlfayk5yr3qhpmay/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"kozistr","short_name":"kozistr","description":"all-rounder","start_url":"/","background_color":"#fff","theme_color":"#6a737d","theme_color_in_head":false,"lang":"ko-KR","display":"standalone","icon":"src/images/favicon-32x32.png","icons":[{"src":"src/images/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/images/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":false,"cache_busting_mode":"query","crossOrigin":"use-credentials","include_favicon":true,"cacheDigest":"043f3a1a5b0faa8db1423d08f05ae012"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-typography@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.11.1_jiti@1.21.0___kv4akajiaqq4jlnfkrjiqcqn3y/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.ts"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.11.1_jiti@1.21.0___encoding@0.1.13_react-dom@18.3._al6d7h7vm4vi2cfgwpvpffyn3y/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
